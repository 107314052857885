import NextErrorComponent from "next/error";
import { Button, Col, notification, Row, Space, Typography } from "antd";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Sentry from "@sentry/nextjs";

import { useAuth } from "@/lib/AuthContext";

import styles from "./_error.module.css";

const { Title, Text } = Typography;

const ErrorComponent = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
    // Flushing is not required in this case as it only happens on the client
  }
  const [errorMessage, setErrorMessage] = useState("defaultErrorMessage");
  const [consoleError, setConsoleError] = useState("");

  const intl = useIntl();
  const router = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    if (err) {
      setConsoleError(err.message);
    }
  }, [err]);

  const getFullPath = () => router.asPath;

  const getReference = ({ path = "", splitter }) => {
    const [reference] = path.split(splitter).slice(-1);
    return reference;
  };
  const getRoute = ({ path }) => {
    const [, route] = path.split("/");
    return route;
  };

  const sendErrorTicketHandler = async () => {
    const userName = user?.name;
    const path = getFullPath();
    const idReference = getReference({ path, splitter: "/" });
    const searchValueReference = getReference({ path, splitter: "?" });
    const reference = idReference || searchValueReference;
    const route = getRoute({ path });

    const body = { userName, route, reference, error: consoleError };
    if (statusCode !== 400 && err && user) {
      await fetch(process.env.NEXT_PUBLIC_SEND_SLACK_MESSAGE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      notification.success({
        message: intl.formatMessage({ id: "ticketSent" }),
      });
    }
  };
  useEffect(() => {
    sendErrorTicketHandler();
  }, [user]);

  useEffect(() => {
    setErrorMessage(
      statusCode === 404 || statusCode === 500
        ? `error${statusCode}`
        : errorMessage
    );
  }, [errorMessage]);

  const goBack = () => {
    location.reload();
  };

  return (
    <Row className={styles.errorWrapper} align="middle" justify="center">
      <Col>
        <Space direction="vertical" align="center">
          <Title className={styles.errorCode}>{statusCode}</Title>
          <Title className={styles.errorMessage}>
            <FormattedMessage id={errorMessage} />
          </Title>
          <img
            src="/static/error.gif"
            alt="error"
            className={styles.errorAnimation}
          />
          <Button onClick={goBack} type="primary" size="large">
            <Text className={styles.buttonText}>
              <FormattedMessage id="reload" />
            </Text>
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

ErrorComponent.getInitialProps = async ({ res, err, asPath }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  });

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  );
  await Sentry.flush(2000);

  return errorInitialProps;
};

ErrorComponent.getLayout = (page) => page;

export default ErrorComponent;
